
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import BillingInfoCardRow from '@/components/pages/admin/billing/BillingInfoCardRow.vue'
import FilterButton from '@/components/shared/filters/FilterButton.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'

const filterOptions = [
  'August 2024',
  'July 2024',
  'June 2024',
  'May 2024',
  'April 2024',
  'March 2024',
  'Custom period',
]

export default defineComponent({
  components: {
    TmDropdownItem,
    FilterButton,
    BillingInfoCardRow,
    TmDropdown,
    InfoCardRow,
    InfoCard,
    TmButton,
  },
  setup() {
    const filterValue = ref(filterOptions[0])

    return {
      filterOptions,
      filterValue,
    }
  },
})
