
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'

export default defineComponent({
  components: {
    InfoCardRow,
    InfoCard,
    TmButton,
  },
})
