
import { defineComponent } from 'vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'

export default defineComponent({
  name: 'BillingInfoCardRow',
  components: { InfoCardRow },
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    icon: {
      type: String,
    },
    btnText: {
      type: String,
    },
  },
})
