import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_billing_empty = _resolveComponent("billing-empty")!
  const _component_billing_plan = _resolveComponent("billing-plan")!
  const _component_billing_details = _resolveComponent("billing-details")!
  const _component_billing_costs = _resolveComponent("billing-costs")!
  const _component_getting_started_faq_byoc = _resolveComponent("getting-started-faq-byoc")!
  const _component_faq_block = _resolveComponent("faq-block")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, {
    "bg-shade": "",
    size: "large",
    "menu-title": "Billing overview",
    class: "admin-billing"
  }, {
    "filter-bar-slot": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-slot")
    ]),
    default: _withCtx(() => [
      (_ctx.isEmptyMode)
        ? (_openBlock(), _createBlock(_component_billing_empty, {
            key: 0,
            white: ""
          }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_billing_plan),
            _createVNode(_component_billing_details),
            _createVNode(_component_billing_costs)
          ], 64)),
      _createVNode(_component_faq_block, {
        title: "Billing FAQ",
        text: "Haven’t found what you were looking for?",
        class: "mt-11"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_getting_started_faq_byoc)
        ]),
        _: 1
      })
    ]),
    _: 3
  }))
}