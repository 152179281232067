
import { defineComponent } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import { useModes } from '@/compositions/modes'
import FaqBlock from '@/components/shared/templates/FaqBlock.vue'
import GettingStartedFaqByoc from '@/components/pages/gettingStarted/GettingStartedFaqByoc.vue'
import BillingPlan from '@/components/pages/admin/billing/index/BillingPlan.vue'
import BillingDetails from '@/components/pages/admin/billing/index/BillingDetails.vue'
import BillingCosts from '@/components/pages/admin/billing/index/BillingCosts.vue'
import BillingEmpty from '@/components/pages/admin/billing/index/BillingEmpty.vue'

export default defineComponent({
  components: {
    BillingEmpty,
    BillingCosts,
    BillingDetails,
    BillingPlan,
    GettingStartedFaqByoc,
    FaqBlock,
    PageContent,
  },
  setup() {
    const { isEmptyMode } = useModes()

    return {
      isEmptyMode,
    }
  },
})
