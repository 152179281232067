
import { defineComponent } from 'vue'
import TmChip from '@/components/shared/TmChip.vue'
import TmButton from '@/components/shared/TmButton.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    InfoCardRow,
    InfoCard,
    TmButton,
    TmChip,
  },
  setup() {
    const { openModal } = useModals()

    return {
      openModal,
    }
  },
})
