import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "neutral--text subhead-medium-16" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_info_card_row = _resolveComponent("info-card-row")!

  return (_openBlock(), _createBlock(_component_info_card_row, {
    title: _ctx.title,
    text: _ctx.text
  }, {
    image: _withCtx(() => [
      _createVNode(_component_tm_icon, {
        name: _ctx.icon,
        size: "xLarge",
        class: "neutral--text"
      }, null, 8, ["name"])
    ]),
    btn: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.btnText), 1)
    ]),
    _: 1
  }, 8, ["title", "text"]))
}